@use '~@kolonialno/kolibri-web/styles/utils/focus-ring';

.LinkContainer {
  // Makes the whole list-item clickable
  ::before {
    content: '';
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0; // allows other links inside the same box take precedence
    width: 100%;
    height: 100%;
  }
}

.AppLink {
  &:focus,
  &:focus-visible {
    @include focus-ring.focus-ring-styles;
  }
}

.dixaFacadeWrapper {
  position: fixed;
  /* Sit on top of the page content */
  z-index: 980;
  right: 30px;
  bottom: 30px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  background: var(--color-semantic-button-primary-default);
  border: medium none;
}

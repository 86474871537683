.k-article {
  max-width: 770px;

  .k-text-style {
    margin-bottom: var(--spacing-7);
  }

  .k-text-style--display-l {
    margin-bottom: var(--spacing-7);
  }

  .k-text-style--display-m {
    margin-top: var(--spacing-9);
    margin-bottom: var(--spacing-5);
  }

  .k-text-style--display-xs {
    margin-top: var(--spacing-9);
    margin-bottom: var(--spacing-5);
  }

  .k-text-style--title-l,
  .k-text-style--title-m,
  .k-text-style--title-s {
    margin-top: var(--spacing-7);
    margin-bottom: var(--spacing-4);
  }

  table .k-text-style,
  a.k-text-style {
    margin-bottom: var(--spacing-0);
  }

  .k-list {
    margin-bottom: var(--spacing-5);
  }

  .k-list-item {
    margin-bottom: var(--spacing-5);
  }

  .k-list-item > .k-list {
    margin-top: var(--spacing-5);
  }
}

.k-article + footer {
  margin-bottom: var(--spacing-10);
}

@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';
@use '../../../uiModules/MiniCart/mini-cart-vars.scss';

.layout {
  min-height: 100vh;
  width: 100%;

  @media (prefers-reduced-motion: no-preference) {
    transition: width mini-cart-vars.$transition-duration ease-in-out;
  }

  &.WithMiniCart {
    @include media-query.breakpoint(xl) {
      width: calc(100% - mini-cart-vars.$mini-cart-width);
    }
  }

}

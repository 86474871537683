.SimpleNavBarWrapper {
  border-bottom: 1px solid var(--color-semantic-border-default);
  grid-template-columns: min-content auto;
  grid-template-areas: 'logo contents';
  
  &.onlyLogo {
    grid-template-columns: auto;
    grid-template-areas: 'logo';
  }
}

.logoArea {
  grid-area: logo;
}

.contentsArea {
  grid-area: contents;
}

.pageTransition {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--color-semantic-pill-spotlight);

  transition: right ease-out 300ms;

  &--start {
    right: 80%;
    opacity: 1;
  }

  &--stop {
    right: 0;
  }

  &--idle {
    opacity: 0;
    right: 100%;
  }
}

@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

$footer-link-color: var(--color-semantic-text-on-footer);

.footer {
  background-color: var(--color-semantic-surface-footer-top);
  border-top: 1px solid var(--color-semantic-border-default);
  border-bottom: 1px solid var(--color-semantic-border-default);
}

.footerBase {
  background-color: var(--color-semantic-surface-footer-bottom);
}

.footerLink {
  color: $footer-link-color;
  font-weight: 400;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:global(.k-icon) {
    margin-left: var(--spacing-2);
    width: 1em;
    height: 1em;
  }

  &:hover {
    color: $footer-link-color;
    text-decoration: underline;
  }

  &:focus {
    color: $footer-link-color;
    text-decoration: underline;
  }
}

.footerButton {
  background: none;
  border: none;
  padding: 0;
}

.footerList {
  list-style: none;
  padding: 0;

  & li {
    margin-bottom: var(--spacing-1);
  }
}

.footerBadge {
  width: 80px;
}

.footerIcon {
  max-width: 32px;
}

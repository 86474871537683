@mixin reset-button-styles {
  background: transparent;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  border-style: none;
  line-height: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  -webkit-appearance: none;
  user-select: none;
}

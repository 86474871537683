@use '~@kolonialno/kolibri-web/dist/global-reset' as *;
@use '~@kolonialno/kolibri-web/brand/vars' as *; // this uses an alias configured in next.config.js
@use '~@kolonialno/kolibri-web/dist/styles' as *;
@use '../uiModules/Legal/components/Article/article' as *;
@use './print' as *;

// global reset type="button"
[type='button'] {
  --webkit-appearance: none;
  --moz-appearance: none;
  appearance: none;
}

// global reset fieldset
fieldset {
  padding: 0;
}

// global reset legend
legend {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

body.kolibri {
  height: stretch; // fix for floating address bar of mobile safari
}

@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';
@use '~@kolonialno/kolibri-web/styles/utils/focus-ring';
@use '../../../../styles/utils/reset-button-styles.scss';

.subHeader {
  height: 44px;
  display: none;

  @include media-query.breakpoint(md) {
    display: flex;
  }
}

.subHeaderAnonymous {
  height: 93px;
  @include media-query.breakpoint(md) {
    height: 44px;
  }
}

.navItem {
  @include reset-button-styles.reset-button-styles;
  color: var(--color-semantic-text-default);

  background: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  position: relative;

  &__active {
    bottom: 0;
    border-bottom: 4px solid var(--color-semantic-interactive-default);
  }

  &:hover {
    color: var(--color-semantic-text-default);
  }

  &:focus,
  &:focus-visible {
    @include focus-ring.focus-ring-styles;
  }
}

.navSection {
  height: 100%;
}

.fixIconInlineBaseline {
  display: flex;
}

.separator {
  @include media-query.breakpoint(md) {
    display: none;
  }
}

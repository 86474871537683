// from https://www.a11y-collective.com/blog/skip-to-main-content/#main-content

.skipToMainContentLink {
  position: absolute; //Positions the link absolutely on the page, allowing it to be placed anywhere.
  left: -9999px; //Moves the link far off-screen to the left, making it invisible to sighted users.
  z-index: 999; //Sets a high z-index, ensuring that the link will be on top of other page elements if it becomes visible.
  padding: 1em;
  opacity: 0; //Sets the link's opacity to 0, making it fully transparent and invisible.
}

.skipToMainContentLink:focus {
  top: var(--spacing-2); // When the link receives focus (e.g., when a keyboard user tabs to it), it is repositioned to the top of the screen.
  left: var(--spacing-2); ; // When the link receives focus (e.g., when a keyboard user tabs to it), it is repositioned horizontally to the centre of the screen.
  opacity: 1; //The link's opacity is set to 1, making it fully visible.
}

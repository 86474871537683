@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';


.NavbarWrapper {
  grid-template-columns: 1fr min-content 1fr;

  grid-template-areas:
  'hamburgerMenu logo iconContainer'
  '. . .'
  'search search search'
  '. . .'
  'deliverySlotButton deliverySlotButton deliverySlotButton';

  grid-template-rows: auto var(--spacing-3) auto var(--spacing-3) auto;

  & .NavbarLogo {
    justify-self: center;
  }


  @include media-query.breakpoint(md) {
    grid-template-columns: min-content auto auto;
    grid-template-areas:
    'logo . iconContainer'
    '. . .'
    'search search search'
    '. . .'
    'deliverySlotButton deliverySlotButton deliverySlotButton';
  }

  @include media-query.breakpoint(lg) {
    gap: 0px;
    grid-template-columns: min-content 32px auto 32px auto 16px min-content;
    grid-template-areas: 'logo . search . deliverySlotButton . iconContainer';
    grid-template-rows: auto;
  }

  &--hideDeliverySlotButton {
    grid-template-rows: auto var(--spacing-3) auto;

    @include media-query.breakpoint(md) {
      grid-template-rows: auto var(--spacing-3) auto;
    }

    @include media-query.breakpoint(lg) {
      grid-template-rows: auto;
    }
  }
}

.NavbarLogo {
  grid-area: logo;
  justify-self: start;

  :global(.k-oda-logo) {
    display: block;
    width: 60px;
    height: 24px;
  }

  :global(.k-mathem-logo) {
    display: block;
    width: 140px;
    height: 40px;
  }
}

.NavbarHamburgerMenu {
  grid-area: hamburgerMenu;

  @include media-query.breakpoint(md) {
    display: none;
  }
}

.NavbarSearchContainer {
  grid-area: search;
}

.NavbarDeliverySlotButton {
  grid-area: deliverySlotButton;
  @include media-query.breakpoint(lg) {
    justify-self: end;
  }
}

.NavbarMegaMenu {
  position: relative;
  grid-area: megaMenu;
  background-color: var(--color-semantic-background-subdued);
  border-bottom: 1px solid var(--color-semantic-border-default);
}

.NavbarIconContainer {
  grid-area: iconContainer;
  .NavbarIcon {
    display: block;
  }
}